/*
 * PMApp::customer_dashboards
 * as a minimum, a job dashboard must implement a "load" handler to setup the job dashboard event handlers;
 * this file does that for customer dashboards; however, customer dashboards require some additional JS setup
 * which is also handled by this file
 */

import { jobDashboardsInitialize } from './job_dashboards.js.erb';
import { showLoadingOverlay      } from "./loading_overlay";
import { periodableFetchParams   } from './periodable.js.erb';
import { pmappPreventDefaults    } from './pmapp.js.erb';


// -- ---- -- -- --
// constants
// -- ---- -- -- --

const pwaDescSelector         = '.pwa-usage-container ' +
                                '.graph-desc'
const pwaInfoToggledLinkClass = 'toggled-on';
const pwaPairsSelector        = '.pwa-usage-container ' +
                                '.pwa-pairs'


// -- ---- -- -- --
// initialization
// -- ---- -- -- --

$(document).on('turbolinks:load', function() {
  if ($('body.customer_dashboards.dashboard').length > 0) {
    jobDashboardsInitialize(customerDashboardsFetchCustomerDashboardParams);

    if ($('.location-select-option').length > 0) {
      customerDashboardsSetupLocationSelectEventHandlers();
    }

    if ($('#allTeamsCheck').length > 0) {
      $('#allTeamsCheck').change(function(event) {
        const targetPath = $(allTeamsCheck).data('url');
        const periodableParams = periodableFetchParams();
        const customerDashboardParams = customerDashboardsFetchCustomerDashboardParams();
        const params = Object.assign(periodableParams, customerDashboardParams);

        showLoadingOverlay();

        $.ajax({
          data:  params,
          type:  'POST',
          url:   targetPath,
          async: true
        });
      });
    }

    if ($('#pwaInfoToggleLink').length > 0) {

      // initialize the PWA info and toggle link state
      $('#pwaInfoToggleLink').removeClass(pwaInfoToggledLinkClass);
      $(pwaDescSelector).hide();
      $(pwaPairsSelector).hide();

      customerDashboardsSetupAllocationUsageEventHandlers();
    }
  }
});


// public methods

export function customerDashboardsGetInfoToggleState() {
  return $('#pwaInfoToggleLink').hasClass(pwaInfoToggledLinkClass);
}

export function customerDashboardsSetInfoToggleState(infoToggledOn) {
  if (infoToggledOn) {
    $('#pwaInfoToggleLink').addClass(pwaInfoToggledLinkClass);
    $(pwaDescSelector).show();
    $(pwaPairsSelector).show();
  }
  else {
    $('#pwaInfoToggleLink').removeClass(pwaInfoToggledLinkClass);
    $(pwaDescSelector).hide();
    $(pwaPairsSelector).hide();
  }
}

/*
 * customerDashboardsSetupAllocationUsageEventHandlers
 * configures event handlers for the Allocation Usage section of the customer dashboard summary tile
 */
export function customerDashboardsSetupAllocationUsageEventHandlers() {
  $('#pwaInfoToggleLink').click(function(event) {
    var e = event || window.event

    pmappPreventDefaults(e);

    var toggleLinkIcon = e.target || e.srcElement;
    var toggleLink = $(toggleLinkIcon).closest('a');

    var infoToggledOn = customerDashboardsGetInfoToggleState();
    customerDashboardsSetInfoToggleState(! infoToggledOn);
  });
}

export function customerDashboardsSetupLocationSelectEventHandlers() {
  $('.location-select-option').click(function(event) {
    var e = event || window.event

    // we don't want these links doing their usual link things...
    pmappPreventDefaults(e);

    // but we do want them doing their bootstrap things
    var optionLink = e.target || e.srcElement;
    var dropDown = $(optionLink).closest('.dropdown-menu');
    dropDown.dropdown('hide');

    var targetPath = $(optionLink).attr('href')

    var params = periodableFetchParams();

    var allTeamsIsChecked = $('#allTeamsCheck').prop('checked');
    if (allTeamsIsChecked) {
      params["all_teams"] = "true";
    }

    $.ajax({
      data:  params,
      type:  'POST',
      url:   targetPath,
      async: true
    });

    showLoadingOverlay();
  });
}


// helpers

function customerDashboardsFetchAllTeamsParams() {
  var allTeamsChecked = $('#allTeamsCheck').prop('checked');

  var allTeams = null;
  if (allTeamsChecked) {
    allTeams = "true";
  }

  return {
    all_teams: allTeams
  }
}

function customerDashboardsFetchCustomerDashboardParams() {
  const locationParams = customerDashboardsFetchLocationParams();
  const allTeamsParams = customerDashboardsFetchAllTeamsParams();

  return Object.assign(locationParams, allTeamsParams);
}

function customerDashboardsFetchLocationParams() {
  const returnValue = { };

  const customerId = $('#customerNameField').data(
    'selected'
  );
  returnValue["id"] = customerId;


  /*
   * at the mo', the location select is a single-select; eventually it will be a multi-select; in the
   * interest of supporting the future state, the location is delivered back to the server as an array
   * of IDs
   */

  const locationId = $('#locationSelect').data(
    'selected'
  );
  if (locationId && locationId != '0') {
    returnValue["location_ids"] = [locationId];
  }
  
  return returnValue;
}
