/*
 * PMApp::team_dashboards.js.erb
 * as a minimum, a job dashboard must implement a "load" handler to setup the job dashboard event handlers;
 * this file does that for the team dashboards
 */

import { jobDashboardsInitialize } from "./job_dashboards.js.erb";

$(document).on('turbolinks:load', function() {
  if ($('body.team_dashboards.dashboard').length > 0) {
    jobDashboardsInitialize();
  }
});
