/*
 * PMApp::job_dashboards.js.erb
 * JS that is specific to job dashboards
 */

import { filterableInitializePageAsFilterable,
         filterableHideShowPageLevelControls      } from "./filterable.js.erb";
import { jobListableComputeJobCount,
         jobListableFetchFilterParams,
         jobListableFetchOtherParamsForFiltering,
         jobListableFetchSortParams,
         jobListableSetupEventHandlers            } from './job_listable.js.erb';
import { periodableFetchParams,
         periodableSetupEventHandlers             } from './periodable.js.erb';
import { pmappPreventDefaults                     } from './pmapp.js.erb';
import { showLoadingOverlay                       } from "./loading_overlay";
import { teamFocusSetupEventHandlers              } from './team_focus.js.erb';


// -- ---- -- -- --
// state
// -- ---- -- -- --

var jobDashboardsIntervalId = null; // for auto-refresh
var jobDashboardsGenerateOutsideParamsForPerioding = null;


// -- ---- -- -- --
// initialization
// -- ---- -- -- --

$(document).on('turbolinks:load', function() {

  // ON ANY PAGE: reset/turn off periodic refresh
  if (jobDashboardsIntervalId) {
    clearInterval(jobDashboardsIntervalId);
    jobDashboardsIntervalId = null;
  }

});


// public

/*
 * jobDashboardsInitialize
 * initialize the current page as a job dashboard; this includes:
 *
 *   1. setting up event handlers for the focus team select control
 *   2. setting up job dashboard specific event handlers
 *   3. configuring periodic refresh if enabled
 *
 * -- ---- -- -- --
 * generateOutsideParamsForPerioding: a specialized job dashboard may need parameters to be delivered
 *                                    to "periodable" actions that are not generally needed by a job dashboard;
 *                                    this argument allows the specialized dashboard to configure that
 *                                    behavior
 */
export function jobDashboardsInitialize(generateOutsideParamsForPerioding) {
  teamFocusSetupEventHandlers();

  jobDashboardsGenerateOutsideParamsForPerioding = generateOutsideParamsForPerioding;
  jobDashboardsSetupEventHandlers();

  // job dashboards always assume that destroying a job is a long running action
  $('a.job-action-option-delete').click(function() {
    showLoadingOverlay();
  });

  // set up perodic refresh if needed
  var refreshPath = $('#dashboardContentContainer').data('refresh-path');
  var oneSecond = 1000;
  var oneMinute = 60 * oneSecond;
  if (refreshPath.length > 0) {
    jobDashboardsIntervalId = setInterval(function() {
      if (refreshPath.length > 0) {
        $('#refreshIndicator').removeClass('hidden');

        $.ajax({
          data:  { },
          type:  'POST',
          url:   refreshPath,
          async: true
        });
      }
    }, 15 * oneMinute);
  }
}

/*
 * jobDashboardsSetupJobListsEventHandlers
 * when job lists are redrawn, but the rest of the dashboard is not, the event handlers will need to be
 * reset for the job lists alone
 */
export function jobDashboardsSetupJobListsEventHandlers() {
  jobListableSetupEventHandlers('.entire-page',
                                jobDashboardsFetchParamsForJobList);
}

/*
 * jobDashboardsSetupPeriodSelectEventHandlers
 * configures event handler for the period select on the job dashboard
 */
export function jobDashboardsSetupPeriodSelectEventHandlers() {
  periodableSetupEventHandlers(jobDashboardsFetchOtherParamsForPerioding);
}


// private

function jobDashboardsFetchOtherParamsForPerioding() {
  var returnValue = null;

  var filterParams = jobListableFetchFilterParams();
  var sortParams = jobListableFetchSortParams();
  returnValue = Object.assign(filterParams, sortParams);

  if (jobDashboardsGenerateOutsideParamsForPerioding &&
      typeof(jobDashboardsGenerateOutsideParamsForPerioding) === typeof(Function)) {
    const outsideParams = jobDashboardsGenerateOutsideParamsForPerioding();
    returnValue = Object.assign(returnValue, outsideParams);
  }

  return returnValue;
}

function jobDashboardsFetchParamsForJobList() {
  var periodParams = periodableFetchParams();
  return Object.assign(periodParams);
}

export function jobDashboardsInitializeAsFilterable() {
  const pageCount = jobListableComputeJobCount();
  const allowFilter = filterableInitializePageAsFilterable(pageCount, jobListableFetchOtherParamsForFiltering);
  filterableHideShowPageLevelControls(! allowFilter);
}

function jobDashboardsSetupEventHandlers() {
  jobDashboardsSetupJobListsEventHandlers();
  periodableSetupEventHandlers(jobDashboardsFetchOtherParamsForPerioding);
  jobDashboardsInitializeAsFilterable()
}

